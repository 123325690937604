
import {
  ref,
  computed,
  onMounted,
  defineComponent,
} from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import FormPage from '@/view/pages/common/ads/FormPage.vue'
import ApiService from '@/core/services/ApiService'
import BasePre from '@/core/components/base/BasePre.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import isEmptyObject from '@/core/helpers/isEmptyObject'
import doesObjectsEqual from '@/core/helpers/doesObjectsEqual'
import ConvertDate from '@/core/helpers/convertDate'

export default defineComponent({
  name: 'ads-edit',
  components: {
    FormPage,
    BasePre,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const entryData = ref({})
    const entryDataForComparing = ref({})
    const formRefParent = ref(null)
    const buttonsLoading = ref(false)


    /**
     * Call the API for an ads single entr1y.
     * @returns {object|null}.
     */
    const requestEntry = async () => {
      ApiService.setHeader()

      return await ApiService.get(`/admin/pubs/${route.params.id}`)
        .then(response => response.data)
          .catch(error => {
            console.error('> run requestEntry > Doesnt passed', error)
            return null
          })
    }


    /**
     * Form changes comparing.
     */
    const formsAreEqual = computed(() => {
      return doesObjectsEqual(entryData.value, entryDataForComparing.value)
    })


    /**
     * Get an ads single entry.
     */
    const getEntry = async () => {
      if (route.params.id) {
        const data = await requestEntry()
        // console.log('>>>> requestEntry', data)
        entryData.value = Object.assign({}, data)
        // console.log('>__ getEntry entryData.value', entryData.value)
        entryDataForComparing.value = Object.assign({}, entryData.value)
      }
    }



    /*********************
    * UPLOAD START
    *********************/

    const preparedPhotos = ref([])

    /**
     * Interface of the image object.
     */
    interface InterfaceObjectPhotos {
      id: number
      url: string
      name: string
      path: string
      original_name: string
      new?: boolean
      raw?: object
    }


    /**
     * Requesting to the server
     * for images uploading.
     * @param {object} Image file
     * @returns {object|null}.
     */
    const requestPhotosToServer = async file => {
      const formData = new FormData()
      formData.append('file', file.raw)

      ApiService.setHeader()

      return await ApiService.post('/admin/file/upload/good', formData)
        .then(response => {
          // console.log('> requestPhotosToServer >> response', response.data)
          return response.data
        })
        .catch(() => {
          ElMessage.error('ApiService file upload error')
          console.log('> requestPhotosToServer >> error')
          return null
        })
    }


    /**
     * Sending photos to server.
     * Iterating the photos list
     * and requesting to the server.
     * @param {object} The entryData object
     * @returns {array}.
     */
    const sendPhotosToServer = async data => {
      const photos = []

      if (data.photos && data.photos.length) {
        for (const item of data.photos) {
          if (item.new) {

            const readyImage: {
              data: InterfaceObjectPhotos
            } = await requestPhotosToServer(item)

            if (
              readyImage
              && !isEmptyObject(readyImage)
              && !isEmptyObject(readyImage.data)
            ) {
              // console.log('> readyImage', readyImage)
              photos.push(readyImage.data)
            }
          } else {
            photos.push(item)
          }
        }
      }

      return photos
    }

    /*********************
    * UPLOAD END
    *********************/



    /*********************
    * FORM ACTIONS START
    *********************/

    /**
     * Form validation.
     * Calling the child method
     * and get the result of the validation.
     * @returns {boolean}.
     */
    const validateForm = () => {
      const result = formRefParent.value.validateForm()
      return result
    }


    /**
     * Request to save the entry data object.
     * @param {object} Entry data object.
     * @returns {boolean}.
     */
    const requestSaveForm = async data => {
      // console.log('> run requestSaveForm', data)

      ApiService.setHeader()

      return await ApiService.post(`/admin/pubs/${route.params.id}`, data)
        .then(() => true)
          .catch(error => {
            ElMessage({
              offset: 20,
              type: 'warning',
              message: 'Не все поля заполнены',
            })
            return false
          })
    }


    /**
     * Preparing the entry data for saving.
     * Removing some properties.
     * Fixing others.
     * @param {object} Entry object.
     * @param {array} Photos array.
     * @returns {object}.
     */
    const prepareDataForSending = (data, photos) => {
      // console.log('> run data', data)
      const result = { ...data }
      const adminData = store.getters.getAdminData
      result.photos = photos.map(item => item.id)
      const newRubrics = {}

      result['rubric_props'].forEach(
        item => {
          if (item.value) {
            newRubrics[item.id] = item.value
            if (item['type_of'] === 'D') {
              console.log('> item.value', item.value)
              newRubrics[item.id] = (new ConvertDate(item.value as string)).convertToYyyyMmDd()
              console.log('> newRubrics[item.id]', newRubrics[item.id])
            }
          }
        }
      )

      result['rubric_props'] = newRubrics

      result['order_type'] = result.order['order_type']
      result['order_quantity'] = result.order['order_quantity']
      result['order_delivery_type'] = result.order['order_delivery_type']
      result['order_delivery_address'] = result.order['order_delivery_address']
      result['order_payment_method'] = result.order['order_payment_method']
      result['order_delivery_address_json'] = result.order['order_delivery_address_json']

      result['address_json'] = data['address_json']
      result['address_id'] = data['address_id']
      result.address = data.address

      if (data.addressString === data.address) {
        result['address_json'] = null
        result['address'] = null
      } else {
        result['address_id'] = null
      }

      result.price = +result.price

      if (result['fixed_until']) {
        const convertedDate = (new ConvertDate(result['fixed_until'] as string)).convertMe()
        result['fixed_until'] = convertedDate
      }

      // console.log('> run result', result)
      return result
    }


    const saveButtonClick = () => {
      console.log('saveButtonClick')
      formRefParent.value.saveForm()
    }


    /**
     * Entry saving.
     * Validation checking.
     * Preparation of the sending object.
     * Calling the server requesting function.
     */
    const saveForm = async (flag) => {
      buttonsLoading.value = true
      // const validResult = await validateForm()
      if (flag) {
        preparedPhotos.value = await sendPhotosToServer(entryData.value)
        const sendData = prepareDataForSending(entryData.value, preparedPhotos.value)
        const resultRequestSaveForm: boolean = await requestSaveForm(sendData)

        if (resultRequestSaveForm) {
          ElMessage({
            offset: 50,
            type: 'success',
            message: 'Объявление сохранёно',
          })
        } else {
          ElMessage({
            offset: 50,
            type: 'warning',
            message: 'Ошибка сохранения объекта',
          })
        }
        buttonsLoading.value = false
      } else {
        buttonsLoading.value = false
      }
    }


    /**
     * Reseting the form.
     * SHowing up the popup for asking the action.
     * Calling the child components method for the form reseting.
     */
    const resetForm = () => {
      ElMessageBox.confirm(
        `Вы действительно хотите <strong>очистить</strong> форму?`,
        'Внимание',
        {
          type: 'warning',
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Очистить',
          dangerouslyUseHTMLString: true,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              instance.confirmButtonText = 'В процессе...'
              formRefParent.value.resetForm()
              done()
              instance.confirmButtonLoading = false
            } else {
              done()
            }
          },
        }
      )
        .then(() => {
          ElMessage({
            type: 'success',
            message: 'Форма очищена',
          })
        })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: 'Отмена сброса',
            })
          })
    }

    /*********************
    * FORM ACTIONS END
    *********************/


    onMounted(() => {
      getEntry()
    })

    return {
      route,
      isEmptyObject,
      // FORM
      entryData,
      formRefParent,
      saveForm,
      resetForm,
      // FORM BUTTONS
      formsAreEqual,
      buttonsLoading,
      saveButtonClick,
    }
  },
})
